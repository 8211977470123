import React, { ReactElement } from "react";

import classNames from 'classnames';
import { WithTranslation, withTranslation } from "react-i18next";
import { useSelector } from 'react-redux';

import styles from './HeaderBackgroundText.css';
import { I18nText } from '../../atoms/i18nText/i18nText';
import { Block } from '../../atoms/Layout/Block/Block';
import { PageBackground } from '../../atoms/PageBackground/PageBackground';
import { environment } from '../../config/environment';
import { PageTypes } from '../../constants/Pages';
import { IGame } from '../../models/Game/Game';
import { CategoryLocalizedRoute, CategoryPageSEO, CoBrandedPageSEO, GeneralPageSEO } from '../../models/PagesData';
import { DeviceType } from '../../services/DeviceDetector';
import { TranslationService } from "../../services/TranslationService";
import { UrlService } from '../../services/UrlService';
import { BreadcrumbItem, Breadcrumbs } from '../Breadcrumbs/Breadcrumbs';

export enum AssetSizes {
    MOBILE = '150x74',
    DESKTOP = '410x202',
}

type HeaderBackgroundTextProps = {
    game?: IGame;
    pageData?: GeneralPageSEO | CategoryPageSEO | CoBrandedPageSEO;
    deviceType?: DeviceType;
    isMyProfile?: boolean;
    pageType: PageTypes;
    publicUsername?: string;
    currentLang: string;
    isAdFree?: boolean;
} & WithTranslation;

type BreadcrumbsSectionProps = {
    links: BreadcrumbItem[];
    className?: string;
    onLightBg?: boolean;
    isSSRLink?: boolean;
};

// TODO: Make a separate component for BreadcrumbsSection

export const BreadcrumbsSection = ({ links, className, onLightBg, isSSRLink }: BreadcrumbsSectionProps) => (
    <Block>
        <Breadcrumbs isSSRLink={isSSRLink} items={links} onLightBg={onLightBg} className={className} />
    </Block>
);

export const categoriesWithDefaultArt = ['All', 'New', 'Game Shows', 'Daily Games', 'AllCategories'];

const getURLForGameCategory = (
    currentLang: string,
    game: IGame,
    pagesFromStore: (GeneralPageSEO | CategoryPageSEO | CoBrandedPageSEO)[]
): string => {
    if (game.primaryCategory) {
        const gamePrimaryCategorySlug = pagesFromStore.find((page) => page.name[currentLang] === game.primaryCategory)
            ?.slug[currentLang];

        if (gamePrimaryCategorySlug) {
            return UrlService.createURL(
                `/${UrlService.getPageLocalizedRoute(currentLang, PageTypes.Category)}/${gamePrimaryCategorySlug}/`
            );
        }
    }

    return UrlService.createURL(`${CategoryLocalizedRoute[currentLang]}/`);
};
const getBreadcrumbLabelForGame = (game: IGame, currentLang: string) => {
    return game!.primaryCategory || (currentLang === 'fr' ? 'Tous' : 'All');
};

export const renderWarningBanner = (className: string, isRemovedGame: boolean): ReactElement => {
    if (!isRemovedGame) {
        return;
    }

    return (
      <p className={className}>
          {TranslationService.translateIt('HEADER_BACKGROUND_TEXT.WARNING_START')}
          &nbsp;
          <a
            href={UrlService.createURL('games/crystal-collapse/')}
            rel="noopener noreferrer"
          >
              {TranslationService.translateIt('HEADER_BACKGROUND_TEXT.CRYSTAL_COLLAPSE')}
          </a>
          {TranslationService.translateIt('HEADER_BACKGROUND_TEXT.WARNING_END')}
          &nbsp;
          <a
            href='https://support.arkadium.com/en/support/home'
            target="_blank"
            rel="noopener noreferrer"
          >
              {TranslationService.translateIt('HEADER_BACKGROUND_TEXT.CONTACT_SUPPORT')}
          </a>.
      </p>
    );
};

const HeaderBackgroundTextBase = React.memo((props: HeaderBackgroundTextProps) => {
    const { currentLang, game, pageData, pageType, isMyProfile, publicUsername, t, isAdFree } = props;
    const categoryPromoData = useSelector((state) => state.categoryPromoData); // should be empty for non categories
    const pagesFromStore = useSelector((state) => {
        if (PageTypes.Game) {
            return state.pages;
        }
    });
    const isRemovedGame = game && game.slug === 'crystal-collapse-odyssey';

    function getBreadcrumbLinks(): BreadcrumbItem[] {
        const breadcrumbsArray: BreadcrumbItem[] = [
            {
                to: UrlService.createURL('/'),
                label: t('HOME_PAGE'),
                position: 1,
            },
        ];

        switch (pageType) {
            case PageTypes.Game: {
                breadcrumbsArray.push(
                    {
                        to: getURLForGameCategory(currentLang, game!, pagesFromStore),
                        label: getBreadcrumbLabelForGame(game!, currentLang),
                        position: 2,
                    },
                    {
                        to: UrlService.createURL(`/games/${game!.alias}/`),
                        label: game!.name,
                        position: 3,
                        game,
                    }
                );
                break;
            }

            case PageTypes.Category: {
                breadcrumbsArray.push({
                    to: UrlService.createURL(
                        `/${UrlService.getPageLocalizedRoute(currentLang, PageTypes.Category)}/${
                            pageData!.slug[currentLang]
                        }/`
                    ),
                    label: pageData!.breadcrumbLabel[currentLang],
                    position: 2,
                });
                break;
            }

            case PageTypes.AllCategories: {
                breadcrumbsArray.push({
                    to: UrlService.createURL(
                        `/${UrlService.getPageLocalizedRoute(currentLang, PageTypes.AllCategories)}/`
                    ),
                    label: pageData!.breadcrumbLabel[currentLang],
                    position: 2,
                });
                break;
            }

            case PageTypes.Profile: {
                breadcrumbsArray.push({
                    to: UrlService.createURL(`/profile/`),
                    label: 'Profile',
                    position: 2,
                });
                break;
            }

            case PageTypes.PrivacyPolicy: {
                breadcrumbsArray.push({
                    to: UrlService.createURL(`/${pageData!.slug[currentLang]}/`),
                    label: pageData!.breadcrumbLabel[currentLang],
                    position: 2,
                });
                break;
            }

            case PageTypes.PrivacyPolicyCalifornia: {
                breadcrumbsArray.push({
                    to: UrlService.createURL(`/${pageData!.slug[currentLang]}/`),
                    label: pageData!.breadcrumbLabel[currentLang],
                    position: 2,
                });
                break;
            }
        }

        return breadcrumbsArray;
    }

    function getTopAsset() {
        switch (pageType) {
            case PageTypes.Game: {
                return (left = true): string => {
                    if (left) {
                        return game!.webGameAreaTopAssetLeft;
                    }

                    return game!.webGameAreaTopAssetRight;
                };
            }

            case PageTypes.Category: {
                const category = pageData as CategoryPageSEO;

                return (left = true): string => {
                    const size = left ? AssetSizes.DESKTOP : AssetSizes.MOBILE;
                    const position = left ? 'left' : 'right';
                    const categoryName = categoriesWithDefaultArt.includes(category.pageName)
                        ? 'Default'
                        : category.pageName.replace(/\s|'/g, '');
                    let url: string;

                    // todo refactor block with content provided from headless cms
                    if (categoryPromoData && category?.pageName === categoryPromoData?.categoryName) {
                        url = categoryPromoData?.topCategoryImage.url;
                    } else {
                        url = `${environment.GAME_ARTS}/_shared_/${categoryName}/webp/top_${position}_${size}.webp`;
                    }

                    return url;
                };
            }

            case PageTypes.Profile:
            case PageTypes.PrivacyPolicy:
            case PageTypes.PrivacyPolicyCalifornia:
            default:
                return () => '';
        }
    }

    function getTitle() {
        switch (pageType) {
            case PageTypes.Game:
                return game!.getSeoTitle();
            case PageTypes.Category:
                const category = pageData as CategoryPageSEO;

                return category.topHeaderH1[currentLang];
            case PageTypes.GameSubmissionDisclaimer:
                return t('HEADER_BACKGROUND_TEXT.DISCLAIMER_TITLE');

            case PageTypes.Profile: {
                if (isMyProfile) {
                    return t('HEADER_BACKGROUND_TEXT.PROFILE_TITLE');
                }

                return <I18nText keyName="PUBLIC_PROFILE" params={{ name: publicUsername || 'Player' }} />;
            }

            case PageTypes.PrivacyPolicy:
                return t('HEADER_BACKGROUND_TEXT.PRIVACY_POLICY_TITLE');
            case PageTypes.PrivacyPolicyCalifornia:
                return t('HEADER_BACKGROUND_TEXT.PRIVACY_POLICY_CALIFORNIA_TITLE');
        }
    }

    function getStyles(): React.CSSProperties {
        const defaultHeaderBackgroundColor = 'rgb(0, 66, 187)';
        const defaultHeaderBackgroundColor2 = 'rgb(13, 111, 221)';
        const defaultHeaderBoxShadow = 'rgba(31, 105, 216, 0.5)';

        switch (pageType) {
            case PageTypes.Game:
                return { backgroundColor: game!.backgroundColor, minHeight: '48vh' }; //#157305 temp fix to avoid white background from PageBackground div

            case PageTypes.Category: {
                const category = pageData as CategoryPageSEO;

                return {
                    backgroundImage: `linear-gradient(258deg, ${category.headerBackgroundColor2}, ${category.headerBackgroundColor})`,
                    boxShadow: `0 4px 20px -5px ${category.headerBoxShadow}`,
                };
            }

            case PageTypes.PrivacyPolicy:
                return {
                    backgroundImage: `linear-gradient(258deg, ${defaultHeaderBackgroundColor2}, ${defaultHeaderBackgroundColor})`,
                    boxShadow: `0 4px 20px -5px ${defaultHeaderBoxShadow}`,
                };
            case PageTypes.PrivacyPolicyCalifornia:
                return {
                    backgroundImage: `linear-gradient(258deg, ${defaultHeaderBackgroundColor2}, ${defaultHeaderBackgroundColor})`,
                    boxShadow: `0 4px 20px -5px ${defaultHeaderBoxShadow}`,
                };
            case PageTypes.Profile:
                return {
                    backgroundImage: `linear-gradient(258deg, ${defaultHeaderBackgroundColor2}, ${defaultHeaderBackgroundColor})`,
                    boxShadow: `0 4px 20px -5px ${defaultHeaderBoxShadow}`,
                };
            default:
                return {};
        }
    }
    
    const classParam = `page${pageType}`;

    return (
      <div className={classNames(styles.areaHeader, styles[classParam], { [styles.warning]: isAdFree && isRemovedGame })}>
          <PageBackground
            pageType={classParam}
            topAsset={getTopAsset()}
            gameHeaderStyles={getStyles()}
            isMyProfile={isMyProfile}
          />
          <div className={styles.areaHeaderContent}>
              <div className={styles.pageTitleContainer}>
                  <h1>{getTitle()}</h1>
              </div>
              <div className={styles.areaHeaderRight}>
                  {(pageType === PageTypes.Category ||
                    pageType === PageTypes.PrivacyPolicy ||
                    pageType === PageTypes.PrivacyPolicyCalifornia) && (
                    <div className={styles.pageHeaderDescription}>
                        <p>{pageData!.onPageTitle[currentLang]}</p>
                    </div>
                  )}
                  <div className={styles.areaBreadcrumbsContainer}>
                      <BreadcrumbsSection links={getBreadcrumbLinks()} />
                  </div>
              </div>
          </div>
          {renderWarningBanner(styles.areaHeaderBanner, isRemovedGame)}
      </div>
    );
});

export const HeaderBackgroundText = withTranslation()(HeaderBackgroundTextBase);
