import React from 'react';

import loadable from '@loadable/component';
import { connect } from 'react-redux';

import styles from './HomepageSlider.css';
import { MiscUtils } from '../../../utils/MiscUtils';
import { TextButton } from '../../atoms/Buttons/TextButton/TextButton';
import { I18nText } from '../../atoms/i18nText/i18nText';
import { FavoritesAnalyticsClickPageTypes } from '../../features/Favorites/FavoritesAnalyticsAi';
import { favoritesService } from '../../features/Favorites/FavoritesService';
import { Button } from '../../FigmaStyleguide/Button/Button';
import { IGame } from '../../models/Game/Game';
import { Analytics } from '../../services/Analytics/Analytics';
import { GameCard, GameCardTypes } from '../GameCard/GameCard';

const SwipeSlider = loadable(() =>
    MiscUtils.loadableRetry(() => import('../../atoms/SwipeSlider/SwipeSlider'), { retries: 3 })
);

type HomepageSliderProps = {
    gameCardType: GameCardTypes;
    items: IGame[];
    caption?: string;
    captionTag?: string;
    noShadows?: boolean;
    promoboxCategory?: string;
    promoBoxPosition?: string;
    onSeeAllClick?: () => void;
    rightButtonClassName?: string;
    overrideRender?: (fn: any, game: IGame, index: number) => JSX.Element;
    game?: IGame;
    headerLink?: string;
    noProgress?: boolean;
    pseudoTile?: boolean;
    userFavoritesList: string[];
    variation?: string;
    doubleSize?: boolean;
};

class HomepageSliderBase extends React.PureComponent<HomepageSliderProps> {
    state = {
        hydrateRerender: 0,
    };

    componentDidMount() {
        const { game } = this.props;

        // the slider used in game page
        if (game) {
            this.trackRecommendedGamesImpressionNew(game);
        }

        this.setState({ hydrateRerender: 1 });
    }

    trackRecommendedGamesImpressionNew = (game: IGame) => {
        const { items } = this.props;
        const recommendedGamesList = items
            .map((g) => g.slug)
            .join(' | ')
            .toLowerCase();

        Analytics.trackEvent(Analytics.games.recommendedGamesImpressionNew(game, recommendedGamesList));
    };

    onItemClick = (game: IGame) => {
        if (this.props.promoboxCategory && this.props.promoBoxPosition) {
            const promoTarget = this.props.promoboxCategory;
            const promoType = `${this.props.gameCardType}|Body`;

            if (promoTarget === 'Hero') {
                Analytics.trackEvent(
                    Analytics.newHome.promoboxSlotClickHero(
                        promoTarget,
                        promoType,
                        this.props.promoBoxPosition,
                        game.slug
                    )
                );
            } else {
                Analytics.trackEvent(
                    Analytics.newHome.promoboxSlotClick(
                        promoTarget,
                        promoType,
                        this.props.promoBoxPosition,
                        game.slug
                    )
                );
            }
        }

        if (this.props.promoboxCategory === 'Game Page') {
            Analytics.trackEvent(
                Analytics.games.recommendedGamesClick(this.props.game, game.slug.toLowerCase())
            );
        }
    };

    renderItem = (game: IGame, index, pseudoTile?: boolean) => {
        return (
            <GameCard
                game={game}
                previousGame={this.props?.game?.alias}
                gameCardType={this.props.gameCardType}
                onClick={this.onItemClick}
                dataElementDescription="homepage-game-slider"
                noProgress={index < 3 ? this.props.noProgress : false}
                pseudoTile={pseudoTile}
                headerLink={this.props.headerLink}
                onSeeAllClick={this.props.onSeeAllClick}
                isFavorite={game?.isFavorite}
                favoritePageType={FavoritesAnalyticsClickPageTypes.HOME}
                doubleSize={this.props.doubleSize}
                variation={this.props.variation}
            />
        );
    };

    renderFnWrapper = (game: IGame, ind: number, pseudoTile?: boolean): JSX.Element => {
        if (this.props.overrideRender) {
            return this.props.overrideRender(this.renderItem, game, ind);
        }

        return this.renderItem(game, ind, pseudoTile);
    };

    render() {
        const CaptionTag = (this.props.captionTag ? this.props.captionTag : 'h2') as any;

        return (
            <div
                className={styles.homepageSlider}
                key={this.state.hydrateRerender}
                data-variation={this.props.variation}
            >
                {this.props.caption && (
                    <div className={styles.captionAndLink} suppressHydrationWarning>
                        <I18nText
                            as={CaptionTag}
                            keyName={this.props.caption}
                            className={styles.caption}
                            link={this.props.headerLink}
                        />

                        {this.props.onSeeAllClick &&
                            (this.props.variation !== 'C' ? (
                                <TextButton
                                    onClick={this.props.onSeeAllClick}
                                    caption="SEE_ALL"
                                    textClassName={styles.seeAllButton}
                                />
                            ) : (
                                <Button
                                    onClick={this.props.onSeeAllClick}
                                    className={styles.button}
                                    outlined
                                    transparent
                                    black
                                    next
                                >
                                    <I18nText as="span" keyName="SEE_ALL" />
                                </Button>
                            ))}
                    </div>
                )}

                <SwipeSlider
                    rightButtonClassName={this.props.rightButtonClassName}
                    buttonClassName={styles[`${this.props.gameCardType}Button`]}
                    noShadows={this.props.noShadows}
                    items={favoritesService.favoritesListSorter(this.props.items,
                        this.props.userFavoritesList
                    )}
                    renderItem={this.renderFnWrapper}
                    promoboxPosition={this.props.promoBoxPosition}
                    pseudoTile={this.props.pseudoTile}
                    headerLink={this.props.headerLink}
                    variation={this.props.variation}
                    doubleSize={this.props.doubleSize}
                />
            </div>
        );
    }
}
const HomepageSlider = connect((state) => ({
    userFavoritesList: state.userFavoritesList,
}))(HomepageSliderBase);

export default HomepageSlider;
